@import "../variables";
@import "~bootstrap/scss/mixins/breakpoints";

header {
  z-index: 2;
  height: 50px;

  .navbar {
    height: 50px;
    border-right: 1px none #ddd;
    border-bottom: 1px solid #d6e2e9;
    background-color: #FFF;

    .container {
      .navbar-brand {
        img {
          height: 30px;
          margin: 0;
          padding: 0;
          width: auto;
        }

        @include media-breakpoint-down(xs) {
          img {
            width: 115px;
          }
        }
      }

      .languages {
        &__item {
          color: #777;
          font-weight: 500;
          font-size: 0.9rem;

          &--bordered {
            border-right: 1px solid #ddd;
          }
          &--active {
            font-weight: 600;
            color: #000;
          }
        }
      }

      .category-menu {
        &__item {
          color: #333333;
          font-size: 1rem;
          padding-right: 15px;
          line-height: 130%;
          text-decoration: none;
          letter-spacing: 0;
          font-weight: 500;
          position: relative;
          border-right: 1px solid #eaeaea;
          margin-right: 15px;

          &--active {
            color: #000;
          }
          &:hover {
            color: #000;
          }
        }
      }

      @include media-breakpoint-down(sm) {
        .container {
          padding: 0;
        }

        .languages {
          &__item {
            font-size: 0.8rem;
          }
        }
        .category-menu {
          display: none;
        }
      }

      @include media-breakpoint-down(lg) {
        .category-menu {
          &__item {
            font-size: 0.6rem;
          }
        }
      }

      .search-block {
        &__btn {
          background: url("../../images/header_huffPost_2x-1535411222681-201808272307.png") 5px 5px no-repeat;
          border: none;
          box-shadow: none;
          width: 30px;
          height: 30px;
          display: inline-block;
          color: transparent;
          background-size: 30px;
        }
      }
    }

    @media (max-width: 576px) {
      .container {
        padding: 0;
      }
    }

    @media (max-width: 768px) {
      .container {
        padding: 0;
      }
    }
  }

  @include media-breakpoint-down(xs) {
    .navbar {
      //width: 376px;
    }
  }
}

@include media-breakpoint-up(md) {
  header {
    height: auto;

    .navbar {
      height: 65px;
    }
  }
}

.navigator {
  background: #fff;
  margin-top: 65px;
  border-bottom: 0 solid #eee;
  box-shadow: 0 2px 5px rgba(0,0,0,.1);

  .menu {
    position: relative;
    height: 40px;
    border-top: 1px none #eee;
    box-shadow: none;
    border-right: 1px solid #ddd;

    &__item {
      color: #333;
      line-height: 18px;
      font-weight: 400;
      text-decoration: none;
      position: relative;
      font-size: 1rem;
      white-space: nowrap;

      &:hover {
        color: #333;
      }

      &--active {
        color: #333;
      }

      &:before {
        content: '#';
      }
    }

    @include media-breakpoint-down(xs) {
      &__item {
        font-size: 0.6rem;
      }
    }
  }

  .currency {
    padding-left: 30px;
    width: 30%;

    &__icon {

    }

    &__item {
      display: inline-block;
      margin-right: 5px;
      padding-right: 5px;
      color: #333;
      font-size: .9rem;
    }
  }
}