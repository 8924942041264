@import "variables";

@import "blocks/header";
@import "blocks/footer";
@import "blocks/breaking-news";
@import "blocks/last-news";
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size: 16px;
}

body {
  font-family: $font-family-base;
  background: #f2f2f2;
  color: #333;
  line-height: 1.3;

  --primary-color: #ee1a24;
  --navbar: #fff;
  --navbar-text: rgba(0, 0, 0, 0.95);
  --text: #333;
  --section-heading: #000;
  --border-color: #d6e2e9;

  --o-2: #ffffff;
  --o-3: #191C1D;
  --o-4: rgba(136, 136, 136, 0.08);
  --b-0: rgba(0, 0, 0, 0);
  --b-01: rgba(0, 0, 0, 0.01);
  --b-05: rgba(0, 0, 0, 0.05);
  --b-10: rgba(0, 0, 0, 0.1);
  --b-25: rgba(0, 0, 0, 0.25);

  &.dark {
    background: #1c1c1c;
    --link-color: #ffffff;
    --navbar: #333;
    --navbar-text: rgba(255, 255, 255, 0.95);
    --text: #fff;
    --section-heading: #fff;
    --border-color: #444;
  }
}


hr {
  margin: 0;
}

.white {
  color: white;
}

//.border_right {
//  border-right: 1px solid #ddd;
//}

.news-section {
  background: #fff;
  border-radius: 13px;
  padding: 20px;
  box-shadow: 0 12px 16px rgb(20 36 56 / 4%);
}

.news {
  &__image {

  }

  &__extract {
    font-size: .8rem;
    margin-top: 12px;
    color: #555;
    letter-spacing: 0;
    padding-right: 10px;
  }

  &__link {
    color: #000;
    font-size: 1rem;
    line-height: 123%;
    font-weight: 600;
    letter-spacing: 0;
  }

  &__date {
    font-size: .8rem;
    line-height: 130%;
    font-weight: 400;
    color: #999;
  }

  &__category {
    color: #000;
    text-decoration: underline;
    font-size: .8rem;
    line-height: 130%;
    font-weight: 400;
  }
}

.page {
  &__title {
    font-size: 1.7rem;
    color: var(--text);
  }

  &__content {
    color: var(--text);

    ul, ol {
      margin-left: 25px;

      li {
        color: var(--text);
        margin-left: 20px;
        margin-bottom: 15px;
      }
    }

    .about-first-col {
      width: 60%;
      display: inline-block;
      float: left;

      table {
        margin-bottom: 40px;
      }
      tr {
        border-bottom: 1px solid #ddd;
      }
      td {
        padding: 8px 10px;
        border: 0 solid #ddd;
        font-size: .9rem;
      }
      p {
        margin-bottom: 1rem;
      }

      blockquote {
        padding: 85px 20px 20px 20px;
        background: url(../images/kovich-icon.png) center 25px no-repeat #EFF7FE;
        background-size: 59px;
        line-height: 150%;
        font-size: 1.1rem;
      }
    }
    .about-second-col {
      width: 30%;
      display: inline-block;
      text-align: center;
      vertical-align: top;
    }
  }
}