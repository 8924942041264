@import "../variables";
@import "~bootstrap/scss/mixins/breakpoints";

.last_news {
  &__title {
    font-size: $font-size-18;
    display: inline-block;
    font-weight: 500;
    color: #013B75;
  }

  .news {
    margin-top: 10px;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #eaeaea;

    &__category {
      color: #777;
      font-size: 0.8rem;
      font-weight: 400;

      //&--molniya {
      //  &:before {
      //    content: '';
      //    background: url("../images/molniya.svg") no-repeat center;
      //    background-size: contain;
      //    width: 8px;
      //    height: 16px;
      //    display: block;
      //    margin: 0 6px 0 0;
      //    float: left;
      //  }
      //}
      //
      //&--video {
      //  &:before {
      //    content: '';
      //    background: url("../images/play.jpg") no-repeat center;
      //    background-size: contain;
      //    width: 15px;
      //    height: 15px;
      //    display: block;
      //    margin: 0 6px 0 0;
      //    float: left;
      //  }
      //}
    }

    &__date {
      color: #aaaaaa;
      font-size: .7rem;
      font-weight: 300;
    }

    &__link {
      font-family: $font-family-base;
      color: #000;
      font-size: .9rem;
      line-height: 133%;
      font-weight: 400;

      &--highlight {
        font-weight: 500;
      }

      &:hover {
        color: inherit;
      }
    }
  }

  &__list {
    //height: 615px;
    //overflow-y: scroll;

    .force-overflow {
      min-height: 650px;
    }

    &::-webkit-scrollbar {
      width: 5px;
      background-color: #c20017;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 20px;
      background-color: #c20017;
    }
  }

  &__all {
    color: #013B75;
  }
}