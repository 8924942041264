@import "../variables";
@import "~bootstrap/scss/mixins/breakpoints";

.breaking-news {
  background: #013B75;
  font-size: 12px;
  padding: 10px;
  position: relative;

  .news {
    &__header {
      color: #fff;
      font-size: .9rem;
      margin-right: 15px;
      border-right: 1px solid;
      padding-right: 15px;
      text-transform: uppercase;
      font-weight: 900;
    }

    &__link {
      color: #fff;
      font-size: 0.9rem;
      font-weight: normal;
    }

    @include media-breakpoint-up(md) {
      &__link {
        font-size: 1rem;
      }
    }

  }
}