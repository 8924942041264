@import "../variables";
@import "~bootstrap/scss/mixins/breakpoints";

.footer {
  color: #888;

  a {
    color: #777;
    text-decoration: underline;
  }

  .border-bottom {
    border-bottom: 1px solid #ddd;
  }
  .logo {
    background: url(../../images/pol-logo-gray.svg) no-repeat 13px 0;
    width: auto;
    height: 30px;
    background-size: contain;
  }

  .social-icons {
    &__title {
      font-size: 16px;
      margin-right: 15px;
      line-height: 200%;
      border-right: 1px solid #ddd;
      padding-right: 15px;
    }
  }

  .pages {
    &__link {
      color: #777;
      text-decoration: underline;
    }
  }
}